<template lang="pug">
div
	transition(name="fademodal" appear)
		ul.projects(v-if="projects")
			li(v-for="(project, index) in projects" :index="index" :key="project.id")
				div.album
					//-em {{project.state}}
					picture
						img(:src="project.artworkCover" :alt="project.artistName+' - '+project.title")
				div.info
					hgroup
						h2 
							a(:href="projectUrl(project)" target="_blank") {{project.title}}
						h3 {{project.artistName}}
						p {{project.trrSku}} / {{project.catNum}}
					div
						dl
							dt Project Status
							dd {{projectStatus(project)}}
						dl
							dt Tracks/Vinyl
							dd {{project.albumTracks.length}} / 1
						dl
							dt Initial Stock
							//-dd {{projectStockQty(project)}}
							dd {{project.stock}}
						dl
							dt Sold Stock
							dd {{projectSalesQty(project)}}
						dl
							dt Album Price
							dd
								Price(:price="project.price.toFixed(2)")
						dl
							dt Album Sales
							dd
								Price(:price="projectSalesTot(project)")
						dl
							dt Dontions
							dd
								Price(:price="projectDonationsTot(project)")
						dl
							dt Remaining Value
							dd
								Price(:price="projectStockVal(project)")
						dl(v-if="project.productionThreshold")
							dt(:class="{below:belowProductonThreshold(project),above:aboveProductonThreshold(project)}") Production Threshold
							dd
								Price(:price="project.productionThreshold.toFixed(2)")
						dl(v-if="project.payoutThreshold !== null")
							dt(:class="{below:belowPayoutThreshold(project),above:abovePayoutThreshold(project)}") Payout Threshold
							dd 
								Price(:price="project.payoutThreshold.toFixed(2)")
						dl(v-if="project.payoutThreshold !== null")
							dt Payout Rate
							dd {{project.payoutPct}}%
						dl(v-if="project.payoutThreshold !== null")
							dt(:class="{above:abovePayoutThreshold(project)}") Payout Total
							dd
								Price(:price="payoutTot(project)")
					button.outline.text.green(@click="toggleChart(project)" :disabled="!project.projectOrders.length")
						span Sales Chart
				div.accordion(:class="{on:activeCharts.includes(project.id)}" :style="accordionHeight(project)")
					h2 Project Sales by Month
					BarChart.chart(:chartData="chartData(project)" :options="chartOptions(project)" :style="chartHeight(project)")
	//-p(v-else) Create your first project.
</template>

<script>
import AccountService from '../services/AccountService';
import Price from '../components/Price';
import dayjs from 'dayjs';
	
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { BarChart } from "vue-chart-3";
Chart.register(...registerables);
Chart.register(annotationPlugin);	
	
export default {
	name: 'AccountProjects',
	components: {
		Price,
		BarChart,
	},	
	data() {
		return {
			projects: null,
			activeCharts: [],
		}
	},
	async created() {
		try {			
			const {data} = await AccountService.getProjects();
			this.projects = data;
			
		} catch(err) {
			console.log('err:',err);			
		}
	},
	computed: {
	},
	methods: {
		projectUrl(project) {
			return '/albums/' + project.slug;
		},
		projectStatus(project) {
			let label;
			
			if (project.state === 'draft') {
				label = 'Draft';
				
			} else if (project.state === 'review') {
				label = 'In Review';
				
			} else {
				label = 'Live';
			}
			
			return label;
		},
		aboveProductonThreshold(project) {
			return parseFloat(this.projectSalesTot(project)) + parseFloat(this.projectDonationsTot(project)) >= project.productionThreshold;
		},
		belowProductonThreshold(project) {
			return parseFloat(this.projectSalesTot(project)) + parseFloat(this.projectDonationsTot(project)) < project.productionThreshold;
		},
		abovePayoutThreshold(project) {
			return parseFloat(this.projectSalesTot(project)) + parseFloat(this.projectDonationsTot(project)) >= project.payoutThreshold;
		},
		belowPayoutThreshold(project) {
			return parseFloat(this.projectSalesTot(project)) + parseFloat(this.projectDonationsTot(project)) < project.payoutThreshold;
		},
		toggleChart(project) {
			const i = this.activeCharts.indexOf(project.id);
			
			if (i === -1) {
				this.activeCharts.push(project.id);
			} else {
				this.activeCharts.splice(i, 1);				
			}
		},
		accordionHeight(project) {
			let height = Number(this.chartHeight(project).replace(/\D/g,'')) + 48;
			return 'height:'+height+'px;';			
		},
		chartHeight(project) {
			const data = this.ordersByMonth(project);
			let i = Object.keys(data).length;
			
//			const footerHeight = 31; // x axis height px
			const footerHeight = 61; // x axis height px
			const chartPad = 10; // title padding still output if hidden, so account for this
			let height = (i * 31) + footerHeight + chartPad;
			
			return 'height:'+height+'px;';
		},
		chartData(project) {			
			const data = this.ordersByMonth(project);
			
			let labels = [];
			let datasetQty = [];
			let datasetQtyTotal = [];
//			let datasetQtyProd = [];
			
			
			for (let key in data) {
				let month = data[key];
				labels.push(month.label);
				datasetQty.push(month.qty);
				datasetQtyTotal.push(month.qtyBefore);
			}
			
			labels.reverse();
			datasetQty.reverse();
			datasetQtyTotal.reverse();
			
			const chartData = {
				labels: labels,
				datasets: [
					{
						label: 'Monthly Sales Qty',
						data: datasetQty,
						barThickness: 10,
						backgroundColor: 'rgba(85,184,159,1)',
					},	
					{
						label: 'Total Sales Qty',
						data: datasetQtyTotal,
						barThickness: 10,
						backgroundColor: 'rgba(85,184,159,.33)',
					},	
				],
			};
			
			return chartData;
		},
		chartOptions(project) {			
			const data = this.ordersByMonth(project);
			
			let datasetValue = [];
			let datasetDonation = [];
			let datasetPayout = [];
			
			for (let key in data) {
				let month = data[key];
				datasetValue.push(month.value);
				datasetDonation.push(month.donation);
				datasetPayout.push(month.payout);
			}
			
			datasetValue = datasetValue.reverse();
			datasetDonation = datasetDonation.reverse();
			datasetPayout = datasetPayout.reverse();
			
			const donationTot = datasetDonation.reduce((sum, value) => sum + value, 0);
			const productionQty = Math.ceil((project.productionThreshold - donationTot) / project.price);			
			const max = project.stock;
			const displayAnnotations = (project.productionThreshold) ? true : false; // not zero or null
			
			let options = {
				responsive: true,
				maintainAspectRatio: false,					
				indexAxis: 'y', // horizontal bar chart
				layout: {
//					padding: 20,
				},
				scales: {
					x: {
						position: 'bottom',
						stacked: true,
						title: {
							display: false,
							text: 'Quantity Sold',
							color: '#56B9A0',
							align: 'start',
							padding: 0,
						},
						min: 0,
						max: max,
						suggestedMax: max,
						ticks: {
							color: '#323232',
							callback: function(value) {
								// wnsure whole numbers only 
								if (Math.floor(value) === value) {
									return value;
								}
							}
							
						},
					},
					y: {
						stacked: true,
						ticks: {
							color: '#323232',
						},
					}
				},
				plugins: {
					autocolors: false,
					annotation: {
						annotations: {
							box1: {
								display: displayAnnotations,
								type: 'box',
								drawTime: 'beforeDraw', // below grid
								borderWidth: 0,
								xMax: productionQty,
								backgroundColor: 'rgba(0, 0, 0, .025)',
							},
							line1: {
								display: displayAnnotations,
								type: 'line',
								label: {
									enabled: true,
									content: 'Production',
									color: '#56B9A0',
									position: 'start',
									textAlign: 'start',
									padding: 0,
									yAdjust: -24,
									backgroundColor: 'transparent',
									font: {
										size: 10,
										weight: 'bold',
									},									
								},
								drawTime: 'afterDraw', // below grid
								borderWidth: 1,
								xMin: productionQty,
								xMax: productionQty,
								borderColor: '#56B9A0',
							},
						}
					},				
					legend: {
						display: false,
					},
					title: {
						display: false,
						text: 'Project Sales by Month',
						padding: 10,
						color: '#333333',
							align: 'start',
						font: {
							size: 18,
							weight: 'normal',
						}
					},						
					tooltip: {
						mode: 'index',
						caretSize: 0,
						xAlign: 'right',
						padding: 5,
						cornerRadius: 4,
						displayColors: false,
						backgroundColor: '#323232',
						titleFont: {
							size: 12,
							weight: 'bold',
						},
						footerFont: {
							size: 12,
							weight: 'normal',							
						},
						callbacks: {
							label: function(context) {
								if (context.datasetIndex === 0) {
									return context.raw + ' Sold';
								} else {
									return null;
								}
							},
							beforeFooter: (context) => {
							
								return 'Value: £' + datasetValue[context[0].dataIndex].toFixed(2);
							},
							footer: (context) => {
								return 'Donations: £' + datasetDonation[context[0].dataIndex].toFixed(2);
							},
							afterFooter: (context) => {
								return 'Payout: £' + datasetPayout[context[0].dataIndex].toFixed(2);
							},
						}
					},
				},
			};
			
			return options;
		},
		payoutTot(project) {
			let payout = 0;
			const months = this.ordersByMonth(project);
			
			for (const key in months) {
				payout += months[key].payout;
			}
			
			return payout.toFixed(2);
		},
		ordersByMonth(project) {
			// total orders by month
			let months = {};
			let qtyBefore = 0;
			let valueBefore = 0;
			let donationBefore = 0;
			
			for (const o of project.projectOrders) {
				const year = dayjs(o.orderDatetime).format('YYYY');
				const month = dayjs(o.orderDatetime).format('MM');
				const label = dayjs(o.orderDatetime).format('MMM YYYY').toUpperCase();
				
				const key = year+month;
				
				if (typeof months[key] === 'undefined') {					
					months[key] = {
						label: label,
						qty: 0,
						value: 0,
						donation: 0,
						qtyBefore: qtyBefore,
						valueBefore: valueBefore,
						donationBefore: donationBefore,
						payout: 0,
					};
				}
				
				months[key].qty += o.qty;
				months[key].donation += parseInt(o.donation);
				
				for (let i=0; i < o.qty; i++) {
					months[key].value += (o.price * 1);
					
					qtyBefore ++;
					valueBefore += (o.price * 1);					
					donationBefore += parseInt(o.donation);					
				}				
			}
			
			if (project.payoutThreshold !== null) {
				// artist payout calculations
				for (const key in months) {
					if ((months[key].valueBefore + months[key].donationBefore) >= project.payoutThreshold) {
						// payout threshold met, so add 20% of monthly sales + donations
						months[key].payout += (project.payoutPct / 100) * months[key].value;
						months[key].payout += months[key].donation;
						
					} else {
						const tot1 = (months[key].valueBefore + months[key].donationBefore + months[key].value);
						const tot2 = (tot1 + months[key].donation);

						if (tot1 > project.payoutThreshold) {
							// payout 20% of sales above the threshold + donations
							let remainder = tot1 - project.payoutThreshold;
							months[key].payout += (project.payoutPct / 100) * remainder;
							months[key].payout += months[key].donation;
							
						} else if (tot2 > project.payoutThreshold) {
							// payout part of donation above the threshold
							months[key].payout += (tot2 - project.payoutThreshold);
						}
					}
				}
			}
			
			return months;
		},
		projectStockQty(project) {
			return project.stock - this.projectSalesQty(project);
		},
		projectStockVal(project) {
			return ( Number(project.stock - this.projectSalesQty(project) ) * project.price );			
		},
		projectSalesQty(project) {
			return (project.projectOrders.length) ? project.projectOrders.reduce((total, order) => total + order.qty, 0) : 0;
		},
		projectSalesTot(project) {
			const tot = (project.projectOrders.length) ? project.projectOrders.reduce((total, order) => total + (order.price * order.qty), 0) : 0;
			return tot.toFixed(2);
		},
		projectDonationsTot(project) {
			let tot = (project.projectOrders.length) ? project.projectOrders.reduce((total, order) => total + parseInt(order.donation), 0) : 0;
			return tot.toFixed(2);
		},
	},
};
</script>

<style lang="scss">
ul.projects {
	li {
		display: flex;
		flex-wrap: wrap;
		padding: 20px 0 10px 20px;
		margin-bottom: 20px;
//		min-height: 70px;
//		border: 2px solid #FFF;
		border-radius: 4px;
		background: #FFF;
		list-style-type: none;
	}
	.album {
		width: 160px;		
		min-width: 160px;
		margin-bottom: 10px;
		box-shadow: 0 5px 10px rgba(#000, .2);
	}
	.info {
		position: relative;
		width: calc(100% - 160px);
		//		flex-grow: 1;
		>div {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			width: 100%;
//			max-width: 640px;
		}
	}
	dl {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-self: stretch;
		margin: 0px 20px 10px 20px;
		min-width: 160px;
		dt {
			display: block;
			font-size: 1rem;
			text-transform: uppercase;
			font-family: "SansBold";
			color: #999999;
			letter-spacing: 0.1em;			
			&.below {
				color: $red;
				+dd {
					color: $red;
				}
			}
			&.above {
				color: $green;
				+dd {
					color: $green;
				}
			}
		}
		dd {
			font-size: 1.4rem;
		}
	}		
	hgroup {
		width: 100%;
		padding: 0 20px 20px 20px;
		h2,
		h3 {
			padding-right: 100px;
		}
		h2 {
			font-size: 1.8rem;
			font-weight: 500;
			a {
				color: $green;
				&:hover {
					color: inherit;
				}
			}
		}
		h3 {
			font-size: 1.6rem;
			font-weight: normal;
		}
		p {
			font-size: 1rem;
			line-height: 1;
			margin-top: 5px;
			font-weight: 600;
		}
	}
	button {
		span {
			&:before {
				box-shadow: none;
			}
		}
	}
	.price {
		em {
			font-size: 1.8rem;
		}
		small {
			font-size: 1.2rem;
			font-weight: 600;
		}
	}
	.accordion {
		position: relative;
		width: 100%;		
		padding-right: 20px;
		overflow: hidden;
		transition: all .3s ease-in-out, opacity .3s .2s ease-in-out;
		&:not(.on) {
			opacity: 0;
			height: 0 !important;
		}
		h2 {
			margin-top: 20px;
			padding-bottom: 10px;
			font-size: 1.8rem;
			font-weight: 500;
			line-height: 1;			
		}
	}
	.chart {
		position: relative;
		width: 100%;		
		/*&:before {
			z-index: 1;
			content: '';
			position: absolute;
			bottom: 29px;
			left: 0;
			width: 70px;
			height: 31px;
			background: rgba(red, .7);
		}
		&:after {
			z-index: 1;
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 70px;
			height: 29px;
			background: rgba(green, .7);
		}*/
	}
	@media only screen and (max-width: 579px) {
		li {
			flex-wrap: wrap;
			padding-left: 0;			
			padding-right: 0;			
		}
		.album {
			width: calc(100% - 40px);
			max-width: 260px;
			margin: 0 auto 20px auto;			
		}
		.info {
			width: 100%;
			div {
				padding: 0 20px;
			}
		}
		.accordion {
			padding: 0 20px;
		}
		dl {
			flex-direction: row;
			align-items: baseline;			
			width: 100%;
			margin: 0 0 10px 0;
			border-top: 1px solid rgba(#000, .1);
			padding-top: 10px;			
		}
		button {
			position: relative;
			left: 50%;
			margin: 10px 0 10px 0;
			transform: translateX(-50%);			
		}
	}
	@media only screen and (min-width: 580px) {
		button {
			position: absolute;
			top: 0;
			right: 20px;			
		}
	}
}
</style>